import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription, switchMap, take } from 'rxjs';
import { Router } from '@angular/router';
import { PasswordService } from '@services/password/password.service';
import { SpinnerService } from '@services/spinner/spinner.service';
import { ToastService } from '@services/toast/toast.service';
import { ConstToastType } from '@interfaces/toast.interfaces';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm!: FormGroup;
  formSubscription: Subscription = new Subscription();
  hide: boolean | undefined;
  hide1: boolean | undefined;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private passwordService: PasswordService,
    private spinnerService: SpinnerService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.hide = true;
    this.hide1 = true;
    this.resetPasswordForm = this.formBuilder.group(
      {
        PASSWORD: [
          '',
          [
            Validators.required,
            Validators.minLength(10),
            Validators.pattern(
              '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@.+$%^&*-]).{10,20}$'
            ),
          ],
        ],
        PASSWORD_CONFIRMATION: ['', Validators.required],
      },
      {
        validator: [this.passwordMatchValidator, this.matchPasswords],
      }
    );
  }

  matchPasswords(formGroup: FormGroup) {
    const password = formGroup.get('PASSWORD')?.value;
    const passwordConfirmation = formGroup.get('PASSWORD_CONFIRMATION')?.value;

    if (password !== passwordConfirmation) {
      return { passwordMismatch: true };
    }

    return null;
  }

  passwordMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get('PASSWORD')?.value;
    const passwordConfirmation = formGroup.get('PASSWORD_CONFIRMATION')?.value;

    let errors = {};

    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSymbol = /[#?!@.+$%^&*-]/.test(password);

    if (password.length < 10) {
      errors = { ...errors, minlength: true };
    }

    if (!hasUpperCase) {
      errors = { ...errors, missingUpperCase: true };
    }

    if (!hasLowerCase) {
      errors = { ...errors, missingLowerCase: true };
    }

    if (!hasNumber) {
      errors = { ...errors, missingNumber: true };
    }

    if (!hasSymbol) {
      errors = { ...errors, missingSymbol: true };
    }

    const errores = Object.keys(errors).length;
    if (errores == 0) {
      return false;
    }
    return formGroup.get('PASSWORD')?.setErrors(errors);
  }

  hasFormErrors(): boolean {
    return (
      this.resetPasswordForm.hasError('minlength', 'PASSWORD') ||
      this.resetPasswordForm.hasError('missingUpperCase', 'PASSWORD') ||
      this.resetPasswordForm.hasError('missingLowerCase', 'PASSWORD') ||
      this.resetPasswordForm.hasError('missingNumber', 'PASSWORD') ||
      this.resetPasswordForm.hasError('missingSymbol', 'PASSWORD') ||
      this.resetPasswordForm.hasError('passwordMismatch') // Validación de contraseñas que coinciden
    );
  }

  onSubmit() {
    this.spinnerService.startLoading();
    const password = this.resetPasswordForm.get('PASSWORD')?.value;
    const password_confirmation = this.resetPasswordForm.get(
      'PASSWORD_CONFIRMATION'
    )?.value;
    const email = this.route.snapshot.queryParams['email'];
    const token = this.route.snapshot.queryParams['token'];
    if (email && token) {
      this.route.queryParams
        .pipe(
          take(1),
          switchMap((params) => {
            return this.passwordService.resetPassword(
              email,
              token,
              password,
              password_confirmation
            );
          })
        )
        .subscribe({
          next: (res) => {
            this.toastService.show(
              'Su contraseña fue modificada con exito',
              ConstToastType.success
            );
            this.spinnerService.stopLoading();
            this.router.navigate(['/login']);
          },
          error: (err) => {
            this.spinnerService.stopLoading();
            this.toastService.show(
              'Ocurrio un error, intente nuevamente',
              ConstToastType.error
            );
            this.router.navigate(['/login/forgot-password'], {
              state: { val: true },
            });
          },
        });
    } else {
      this.toastService.show(
        'Error en la carga de email y token. Cargue de nuevo el link de su casilla',
        ConstToastType.error
      );
      this.router.navigate(['/login/forgot-password'], {
        state: { val: true },
      });
    }
  }
}

import { Injectable } from '@angular/core';
import { ICmsResponse } from '@interfaces/cms.interfaces';
import { IRolesResponse } from '@interfaces/roles.interfaces';
import {
  ISystemVariableStatusSection,
  ISystemVariablesResponse,
} from '@interfaces/system-variables.interfaces';
import { IParameter } from '@interfaces/parameter.interfaces';
import { INotificationsResponse } from '@interfaces/notifications.interfaces';
import { IApiResponse } from '@interfaces/chat.interfaces';
import { IUser } from '@interfaces/user.interfaces';
import { BehaviorSubject } from 'rxjs';
import { ICodesStorage } from '@interfaces/invoices.interfaces';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private readonly CMS = 'AGD_CMS';
  private readonly ROLES = 'AGD_ROLES';
  private readonly SYSTEM_VARIABLES = 'AGD_SYSTEM_VARIABLES';
  private readonly PARAMETERS = 'AGD_PARAMETERS';
  private readonly NOTIFICATIONS = 'AGD_NOTIFICATIONS';
  private readonly CHAT_THREADS = 'AGD_CHAT_THREADS';
  private readonly TOKEN = 'AGD_TOKEN';
  private readonly USER = 'AGD_USER';
  private readonly RUBROS = 'RUBROS';
  private readonly PERMISSIONS = 'AGD_PERMISSIONS';
  private readonly STATUSBYSECTION = 'STATUS_BY_SECTION';
  private readonly CODES = 'CODES';

  userDataChanged = new BehaviorSubject<IUser['data'] | null>(null);

  constructor() {}

  private setItem(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  private getItem(key: string): any {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  }

  private removeItem(key: string) {
    localStorage.removeItem(key);
  }

  setCms(data: ICmsResponse['data']) {
    this.setItem(this.CMS, data);
  }

  setRoles(data: IRolesResponse['data']) {
    this.setItem(this.ROLES, data);
  }

  setSystemVariables(data: ISystemVariablesResponse['data']) {
    this.setItem(this.SYSTEM_VARIABLES, data);
  }

  setParameters(data: IParameter['data']) {
    this.setItem(this.PARAMETERS, data);
  }

  setNotifications(data: INotificationsResponse['rows']) {
    this.setItem(this.NOTIFICATIONS, data);
  }

  setChatThreads(data: IApiResponse['data']) {
    this.setItem(this.CHAT_THREADS, data);
  }

  setPermissions(data: string[]) {
    this.setItem(this.PERMISSIONS, data);
  }

  setStatusBySection(data: ISystemVariableStatusSection) {
    this.setItem(this.STATUSBYSECTION, data);
  }

  setCodes(data: ICodesStorage) {
    this.setItem(this.CODES, data);
  }

  getSystemVariables() {
    return this.getItem(
      this.SYSTEM_VARIABLES
    ) as ISystemVariablesResponse['data'];
  }

  getStatusBySection() {
    return this.getItem(this.STATUSBYSECTION) as ISystemVariableStatusSection;
  }

  getCms() {
    return this.getItem(this.CMS) as ICmsResponse['data'];
  }

  getPermissions() {
    return this.getItem(this.PERMISSIONS) as string[];
  }

  getNotifications() {
    return this.getItem(this.NOTIFICATIONS) as INotificationsResponse['rows'];
  }

  getChatThreads() {
    return this.getItem(this.CHAT_THREADS) as IApiResponse['data'];
  }

  getParameters() {
    return this.getItem(this.PARAMETERS) as IParameter['data'];
  }

  getPaymentConditions(ERP = 'JDE') {
    return this.getParameters().paymentsConditions.filter(pc => {
      return pc.ERP === ERP && pc.hidden === 0;
    });
  }

  getRoles() {
    return this.getItem(this.ROLES) as IRolesResponse['data'];
  }

  setToken(data: string) {
    this.setItem(this.TOKEN, data);
  }

  setUserData(data: IUser['data'] | '') {
    this.setItem(this.USER, data);
    this.userDataChanged.next(null);
  }

  getToken() {
    return this.getItem(this.TOKEN);
  }

  getUserData() {
    return this.getItem(this.USER) as IUser['data'];
  }

  getCodes() {
    return this.getItem(this.CODES) as ICodesStorage;
  }
}
